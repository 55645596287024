import React, { useEffect, useState } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const PageTemplate = () => {
  return (
    <Layout>
      <SEO
        title="Quem Somos"
        description="Em nome de Allah, O Clemente, O Misericordioso Fundado em 2014, o Iqara Islam nasceu como um portal com o objetivo de difundir o conhecimento sobre a religião Islâmica aos falantes da língua portuguesa, abordando temas diversificados, que atendam a demanda do público por conhecimento, procurando sempre fornecer conteúdos únicos e soluções para o dia"
        url={`${process.env.GATSBY_HOST_URL}/quem-somos`}
      />

      <div
        class="content-grid page-grid quem-somos"
        style={{ maxWidth: "1100px" }}
      >
        <div class="content content-custom">
          <h1 itemprop="name" itemtype="http://schema.org/Thing">
            Quem Somos
          </h1>
          <div>
            <p>
              <strong>Em nome de Allah, O Clemente, O Misericordioso</strong>
            </p>

            <p>
              Fundado em 2014, o Iqara Islam nasceu como um portal com o
              objetivo de difundir o conhecimento sobre a religião Islâmica aos
              falantes da língua portuguesa, abordando temas diversificados, que
              atendam a demanda do público por conhecimento, procurando sempre
              fornecer conteúdos únicos e soluções para o dia a dia do muçulmano
              e dos interessados no Islam.
            </p>

            <p>
              Em 2019, decidimos dar um passo adiante e o Iqara Islam se tornou
              uma empresa, com o objetivo de fornecer serviços educacionais para
              o público falante da língua portuguesa.
            </p>

            <h2>Linha Editorial</h2>

            <p>
              Esse projeto é livre de qualquer filiação Islâmica, ainda que seja
              um projeto administrado por muçulmanos sunitas, sendo um projeto
              voltado para o agrado de Allah primeiramente e em segundo para o
              benefício da humanidade, primeiro da comunidade muçulmana, a maior
              interessada nesses assuntos e depois para a comunidade humana que
              busca o Islam.
            </p>

            <p>
              O Iqara Islam possui um grupo de autores que possuem visões
              semelhantes, mas não idênticas sobre o Islam e portanto visões
              diferentes poderão ser apresentadas e nem todos os textos irão
              tratar um mesmo assunto da mesma forma.
            </p>

            <p>
              Nos esforçamos para trazer sempre a visão das{" "}
              <a
                href="https://iqaraislam.com/o-que-e-uma-escola-de-jurisprudencia-e-por-que-e-necessario-seguir-uma-sheykh-nuh-ha-mim-keller/"
                target="_blank"
                rel="noreferrer noopener"
              >
                4 escolas de jurisprudência
              </a>{" "}
              em nossos textos, pois a nossa posição é a de manutenção da
              Tradição Islâmica, iniciada com o{" "}
              <a
                href="https://iqaraislam.com/breve-biografia-do-profeta-muhammad/"
                target="_blank"
                rel="noreferrer noopener"
              >
                Profeta Muhammad
              </a>{" "}
              e mantida por seus virtuosos seguidores.
            </p>

            <h2>Responsável Religioso</h2>

            <div class="wp-block-image">
              <figure class="aligncenter size-large is-resized">
                <img
                  src="https://images.iqaraislam.com/wp-content/uploads/2020/11/Sheikh-Khaled.jpg"
                  alt="Sheikh Khaled"
                  class="wp-image-8869"
                  width="150"
                  height="150"
                  srcset="https://images.iqaraislam.com/wp-content/uploads/2020/11/Sheikh-Khaled.jpg 500w, https://images.iqaraislam.com/wp-content/uploads/2020/11/Sheikh-Khaled-300x300.jpg 300w, https://images.iqaraislam.com/wp-content/uploads/2020/11/Sheikh-Khaled-150x150.jpg 150w, https://images.iqaraislam.com/wp-content/uploads/2020/11/Sheikh-Khaled-70x70.jpg 70w"
                  sizes="(max-width: 150px) 100vw, 150px"
                />
                <figcaption>
                  <a
                    href="https://www.facebook.com/khaleddin"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    Sheikh Khaled Taky El Din
                  </a>
                </figcaption>
              </figure>
            </div>

            <p>
              Graduado na Universidade Islâmica de Medina (Arábia Saudita) pela
              Faculdade de Dawa e Princípios da Religião no ano de 1986, Sheikh
              Khaled é um destacado divulgador do Islam no Brasil, país que
              reside há mais de 20 anos.
            </p>

            <p>
              Atual secretário do Conselho Superior de Teólogos e Assuntos
              Islâmicos do Brasil, Sheikh Khaled também é auditor religioso no
              mercado Halal e assessor religioso da União de Estudantes
              Muçulmanos no Brasil.
            </p>

            <p>
              Fundou e orientou diversos projetos de impacto nacional e foi imam
              de diversas mesquitas ao longo de sua trajetória. Possui livros,
              estudos e pesquisas publicados abordando a questão dos muçulmanos
              na América Latina e Brasil.
            </p>

            <h2>Fonte de Renda</h2>

            <p>O Iqara Islam é financiado por:</p>

            <ul>
              <li>
                Publicidade (banners, entrevistas patrocinadas, Google Adsense)
              </li>
              <li>Doações (Apoia.se, doação dos proprietários)</li>
            </ul>

            <p>
              Não possuímos qualquer relação de dependência com qualquer
              entidade nacional ou internacional. Não recebemos contribuições,
              financeiras ou não, de qualquer governo.
            </p>

            <h2>Missão</h2>

            <p>
              Criar um ambiente propício para as relações interpessoais da
              comunidade Islâmica, onde ela possa aprender, conviver,
              compartilhar experiências religiosas e profissionais, ter acesso a
              conteúdo de qualidade, e promover melhorias em suas sociedades de
              modo que reflita os valores tradicionais islâmicos.
            </p>

            <h2>Visão</h2>

            <p>
              Ser a principal referência em ensino e conteúdo sobre o Islam em
              língua portuguesa, impactando positivamente milhões de pessoas.
            </p>

            <h2>Valores</h2>

            <ol>
              <li>Retribuição equânime </li>
              <li> Preservar a Tradição</li>
              <li> Foco na eficiência</li>
              <li> Manutenção do profissionalismo</li>
              <li> Organização </li>
              <li> Adaptação à tecnologia de modo ético</li>
              <li> Amor pela ação justa</li>
              <li> Filantropia e ajuda aos que mais precisam</li>
              <li> Transmissão do conhecimento benéfico</li>
              <li> Aprender com os próprios erros</li>
            </ol>

            <h2>Responsável Técnico</h2>

            <div class="wp-block-image is-style-default">
              <figure class="aligncenter size-large is-resized">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/iqara-islam.appspot.com/o/mansur-peixoto.jpeg?alt=media"
                  alt="Mansur Peixoto Perfil"
                  class="wp-image-7398"
                  width="150"
                  height="150"
                  style={{ height: 150 }}
                />
                <figcaption>
                  <a
                    href="https://www.facebook.com/victor.peixoto.9400"
                    target="_blank"
                    rel="noreferrer noopener"
                    aria-label="Mansur Peixoto (abre numa nova aba)"
                  >
                    Mansur Peixoto
                  </a>
                  , Gestor de Conteúdo e Redes Sociais
                </figcaption>
              </figure>
            </div>

            <p>
              Digital influencer e produtor de conteúdo com impacto em mais de
              60 mil pessoas por mês. Estudante da história e religião Islâmica,
              falante de árabe, inglês e espanhol. Estudou a religião islâmica e
              árabe no Egito e continua seus estudos no Brasil.
            </p>

            <h2>Empresa Responsável</h2>

            <p>Taha Brasil Tecnologia e Marketing LTDA</p>

            <p>CNPJ 35.950.269/0001-13</p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default PageTemplate
